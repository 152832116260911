import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import BlogBanner from '../../components/BlogBanner'
import BlogHeader from '../../components/BlogHeader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function BlogPost({ data }) {
  return (
    <LiveChatLayout>
      <Seo title="Представляем распознавание в режиме реального времени" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-section">
        <div className="blog-content">
          <Link to="/blog" className="back-link">
            <img src={BackArrow} alt="Главная" />
          </Link>
          <BlogHeader
            title="Представляем распознавание в режиме реального времени"
            date="1 октября 2021"
          />
          <p>
            В ходе профессиональной деятельности мы постоянно общаемся, 
            ведем протоколы встреч, отправляем письма и обмениваемся идеями.
          </p>
          <p>
            В условиях занятости людей, и когда тысячи идей конкурируют 
            за наше внимание и личное время, 
            очень просто упустить важную информацию.
          </p>
          <p>
            Для большинства из вас VoxRec уже помогает с транскрипцией
            встреч, электронной почты, консультаций, интервью и других задач, 
            где информация расшифровывается и используется далее по назначению.
          </p>
          <p>
            Некоторые не используют технологию перевода речи в текст
            в повседневной жизни и даже не замечают, как усложняют рабочий процесс.
          </p>
          <p>
            Для некоторых людей общение голосом - сложная задача. Люди с
            нарушениями, дислексией и те, кто не говорит на вашем родном
            языке, испытывают трудности с пониманием того, что вы говорите,
            что совершенно недопустимо в современных условиях.
          </p>
          <p>
            Сегодня мы расширяем возможности нашего сервиса по переводу речи в текст, представляя
            распознавание в режиме реального времени в нашем приложении. 
            Ваша запись фиксируется в обычном режиме, а текст отображается на экране в реальном времени.
          </p>
          <p>
            Мы надеемся, что такая возможность будет полезна пользователям, особенно
            тем, для кого работать в обычных условиях
            является непростой задачей.
          </p>
          <BlogBanner
            title="Мгновенное распознавание речи в текст"
            text="Смотрите и читайте, о чем говорится без переводчика и без необходимости ждать. Попробуйте распознавание в реальном времени в VoxRec."
            withBackground
            picture={
              <StaticImage
                src="../../images/blog/blog-live-transcribe.png"
                className="banner__image"
                alt="VoxRec на iOS"
              />
            }
            appStoreLink={data.site.siteMetadata.appStoreLink}
          />
          <h2>Удобство цифрового распознавания речи в текст</h2>
          <p>
            ВОЗ сообщает, что в мире количество глухих людей и
            людей с нарушениями слуха составляет около 466 миллионов.
          </p>
          <p>
            Правительства многих стран стремятся создать равные возможности для
            всех людей. Приложения с транскрипцией в реальном времени воплощают эту идею в
            реальную жизнь.
          </p>
          <p>
            Миллионы людей не могут печатать или делать
            записи. Причиной тому является дислексия, дисграфия, травмы или другие
            нарушения, ограничивающие движения рук или влияющие на когнитивные способности, с
            транскрипцией в реальном времени такие люди могут принимать участие в онлайн или
            офлайн разговорах, встречах или событиях.
          </p>
          <h2>Распознавание в реальном времени на международных встречах и конференциях</h2>
          <p>
            В современном мире на встречах и конференциях присутствуют участники
            из разных стран. Распознавание в реальном времени помогает профессионалам на
            международных встречах слышать и видеть, что обсуждается
            в данный момент. Так можно сосредоточиться на теме и идеях выступления, нежели
            на попытке понимать сам язык.
          </p>
          <h2>Распознавание речи в реальном времени на занятиях и лекциях</h2>
          <p>
            Лекторы и студенты становятся эффективнее в своей деятельности, если
            использовать технологию распознавания речи на базе ИИ на
            занятиях.
          </p>
          <p>
            Студенты с нарушениями здоровья или не владеющие языком могут
            использовать VoxRec, чтобы понимать лектора в реальном времени в текстовом формате.
            Так процесс обучения и понимания изучаемого предмета становится эффективнее и лучше
            в контексте презентации.
          </p>
          <p>
            После окончания лекции VoxRec отправит точный
            транскрипт на email и его можно использовать для изучения темы лекции.
          </p>
          <p>
            Аналогично, на конференциях и презентациях производится расшифровка выступления
            с разбором слов выступающего. Это полезно в условиях, когда
            отсутствует переводчик для глухих и слабослышащих людей.
          </p>
          <h2>Попробуйте распознавание в реальном времени в VoxRec</h2>
          <p>
            Вы можете активировать и попробовать распознавание в реальном времени
            на экране записи в приложении VoxRec. Нажмите на изображение робота (🤖),
            говорите естественно и смотрите, как появляется текст на экране
            в ходе вашего разговора. В такое сложно поверить!
          </p>
          <p>
            Вы можете настроить размер шрифта текста справа на экране, чтобы его
            было легче читать.
          </p>
          <p>
            После завершения записи у вас на руках будет аудио запись и
            ее расшифровка, доступные для проверки или экспорта.
          </p>
          <p>
            Примечание - созданный в режиме реального времени транскрипт не может быть автоматически отправлен вам на email,
            как это происходит при использовании обычного облачного
            распознавания заранее записанного аудио.
          </p>
          <p>
            Если вы хотите знать больше, прочитайте наши актуальные{' '}
            <a href="/faq">ЧАВО на нашем сайте</a> или внутри приложения.
          </p>
          <p>
            Поделитесь мнением о новой возможности распознавания речи в реальном времени
            - всегда будем рады его услышать!
          </p>
        </div>
      </section>
    </LiveChatLayout>
  )
}

export const data = graphql`
  query {
    site {
      siteMetadata {
        appStoreLink
      }
    }
  }
`
